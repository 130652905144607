
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Button, Typography } from "antd";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import DefaultLayout from "../layouts/default.layout";

const { Title } = Typography;

const PageNotFoundPage = () => {
  const { t, lang } = useTranslation("404");
  const router = useRouter();

  const onOk = () => {
    router.push("/find");
  };

  return (
    <>
      <div
        style={{
          width: "25%",
          textAlign: "center",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "fixed",
        }}
      >
        <Title level={5}>{t("title")}</Title>
        <Button
          onClick={() => onOk()}
          type="primary"
          htmlType="submit"
          style={{
            width: "100%",
            height: "50px",
            fontSize: "1.5em",
            borderRadius: "8px",
          }}
        >
          {t("button")}
        </Button>
      </div>
    </>
  );
};

PageNotFoundPage.layout = DefaultLayout;
export default PageNotFoundPage;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  